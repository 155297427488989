import { useEffect } from "react";
import Footer from "./Footer/footer"
import Header from "./Header/header"

const Layout = ({ children, activeTab }) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://app.termly.io/resource-blocker/42c5becb-f97e-49bf-9973-ddb21f575c54?autoBlock=on';
    
        const rootDiv = document.getElementById('root'); // Replace 'root' with the id of your target div
        if (rootDiv) {
          rootDiv.appendChild(script);
        }
    
        return () => {
          if (rootDiv) {
            rootDiv.removeChild(script);
          }
        };
      }, []);
    return (
        <>
            <Header activeTab={activeTab} />
            {children}
            <Footer />
        </>
    )
}
export default Layout